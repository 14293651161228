<template>
  <div class="">
    <div class="right-word">
      <h2>Live Broadcast Managment Coming Soon</h2>
      <div class="content-name">
        <p class="content-user">{{ $t("setting.roomIndex") }}</p>
        <p class="code-div" style="padding-left: 28px; margin-top: 22px">
          {{ roomIndex }}
        </p>
        <el-button @click="joinRoom" type="primary">Join Room</el-button>
      </div>
      <div class="content-name">
        <p class="content-user">{{ $t("setting.extractServer") }}</p>
        <p class="code-div active-copy" @click="copyUrl" ref="urlToCopy">
          <el-tooltip class="item" effect="dark" content="Click to copy" placement="top">
            {{ pushUrl }}
          </el-tooltip>
        </p>
      </div>
      <div class="content-name">
        <p class="content-user">{{ $t("setting.streamKey") }}</p>
        <p class="code-div active-copy" @click="copyText" ref="textToCopy">
          <el-tooltip class="item" effect="dark" content="Click to copy" placement="top">
            {{ streamKey }}
          </el-tooltip>
        </p>
      </div>
      <div class="content-buttom">
        <el-button
          type="primary"
          size="small"
          color="#02758a"
          dark="#0f333a"
          @click="openLiveRoom"
        >
          {{ $t("setting.openLiveRoom") }}</el-button
        >

        <el-button type="danger" size="small" @click="closeLiveRoom">
          {{ $t("setting.closeLiveRoom") }}</el-button
        >
      </div>

      <h1>Help</h1>
      <br />
      <el-button @click="openHref" type="warning">Live help</el-button>
    </div>
  </div>
</template>

<script setup>
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import { ref, reactive, onMounted, computed, watch } from "vue";
import { openLive, closeLive } from "@/api/profileInfo";
import { Switch } from "@element-plus/icons-vue";
import request from "@/utils/request.js";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { fa } from "element-plus/es/locale";
import { phoneStore } from "../../../store/phone1";

const store1 = phoneStore();
const router = useRouter();
const route = useRoute();
const store = useStore();
const pushUrl = computed(() => {
  return store.state.liveRoom?.pushUrl;
});
const streamKey = computed(() => {
  return store.state.liveRoom?.streamKey;
});
const roomIndex = computed(() => {
  return store.state.liveRoom?.roomIndex;
});

const changeCoinBalance = ref(0);
const guwenInfo = ref(null);
const URL = process.env.VUE_APP_BASE_URL;
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const creditsVal = ref("");
let textToCopy = ref();
let urlToCopy = ref();

const copyUrl = () => {
  let textToCopy1 = urlToCopy.value;
  let tempInput = document.createElement("input");
  tempInput.value = textToCopy1.textContent;
  document.body.appendChild(tempInput);

  tempInput.select();
  tempInput.setSelectionRange(0, 99999);

  document.execCommand("copy");
  document.body.removeChild(tempInput);

  ElMessage({ type: "success", message: "Copied to clipboard!" });
};

const copyText = () => {
  let textToCopy1 = textToCopy.value;
  let tempInput = document.createElement("input");
  tempInput.value = textToCopy1.textContent;
  document.body.appendChild(tempInput);

  tempInput.select();
  tempInput.setSelectionRange(0, 99999);

  document.execCommand("copy");
  document.body.removeChild(tempInput);

  ElMessage({ type: "success", message: "Copied to clipboard!" });
};

watch(creditsVal, () => {
  postConverted();
});

onMounted(() => {
  store1.isConsultingSidebar = false;
  getLoginConsuInfo();
});

//开启直播
const openLiveRoom = async () => {
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  let data = {
    liveType: 1,
  };
  let res = await openLive(data);
  if (res.code == 200) {
    loading.close();
    store.commit("setLiveRoom", JSON.stringify(res.data));
    ElMessage({ type: "success", message: "Live streaming started" });
  } else {
    loading.close();
    ElMessage({ type: "error", message: "Failed to enable live streaming" });
  }
};

//关闭直播
const closeLiveRoom = async () => {
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  let res = await closeLive();
  if (res.code == 200) {
    loading.close();
    store.commit("setLiveRoom", null);
    ElMessage({ type: "success", message: "Closed live successfully" });
  } else {
    loading.close();
    ElMessage({ type: "error", message: "Failed to close live stream" });
  }
};
const joinRoom = () => {
  console.log(guwenInfo.value.id, "-----guwenInfo-----");
  router.push({ path: `/detailPage/${guwenInfo.value.id}` });
};

async function postConverted() {
  if (creditsVal.value == "") {
    changeCoinBalance.value = 0;
    return;
  }
  const { code, data } = await request.post("/system/faConsultant/converted", {
    coinBalance: creditsVal.value,
  });
  if (code == 200) {
    changeCoinBalance.value = data;
  }
}
async function postWithdrawApply() {
  const { code, data } = await request.post("/system/FaWithdrawal/withdrawApply", {
    coinBalance: creditsVal.value,
  });
  if (code == 200 && data) {
    getLoginConsuInfo();
    ElMessage({
      type: "success",
      message: "Submit successfully",
    });
    creditsVal.value = "";
    setTimeout(() => {
      router.push("/profileInfo/WithdrawalList");
    }, 500);
  }
}
function submit() {
  ElMessageBox.confirm("Are you sure to submit?", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
  })
    .then(() => {
      postWithdrawApply();
    })
    .catch(() => {});
}
async function getLoginConsuInfo() {
  console.log("请求用户信息");
  const { code, data } = await request.get("/system/faConsultant/getLoginConsuInfo");
  if (code == 200) {
    guwenInfo.value = data;
    // 储存用户信息
    store.commit("setUserInfo", JSON.stringify(data));
  }
}
function openHref() {
  window.open(window.location.origin + "/#/obsHelp");
}
</script>

<style lang="scss" scoped>
.right-word {
  line-height: 51px;
  padding-left: 20px;
  color: #02758a;

  h2 {
    border-bottom: 1px solid #c2bcbc;
  }

  .content-name {
    padding-right: 20px;
    display: flex;
    height: 50px;
    color: black;
    font-size: 18px;
    width: 100%;
    margin-top: 5px;
    align-items: center;

    .content-user {
      width: 200px;
    }

    .code-div {
      min-height: 50px;
      padding: 5px;
      line-height: 21px;
      border-radius: 5px;
      width: 80%;
      word-wrap: break-word;
      font-size: 16px;
      margin-top: 15px;
    }

    .active-copy {
      cursor: pointer;
    }
  }

  .content-buttom {
    width: 100%;
    text-align: center;
    height: 70px;
    margin-top: 30px;
  }
}
</style>
